// 意见反馈
<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import moment from 'moment'
import { getAction } from '@/command/netTool'
import { byAuditTypeList } from '@/utils/textFile'

export default {
  name: 'feedback',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/audit/farmAuditFeedback/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '受理单号',
          type: 'input',
          key: 'id',
        },
        {
          name: '反馈时间',
          type: 'rangePicker',
          keys: ['beginDate', 'endDate'],
        },
        {
          name: '手机号码',
          type: 'input',
          key: 'mobile',
        },
      ]
    },
    getColumns() {
      let _this = this
      return [
        {
          dataIndex: 'id',
          title: '受理单号',
          isId: true,
          align: 'left',
        },
        {
          dataIndex: 'mobile',
          title: '手机号码',
          align: 'left',
        },
        {
          dataIndex: 'auditType',
          title: '反馈类型',
          align: 'left',
          onExport: (records) => (byAuditTypeList().find((e) => e.value == records) || { name: '' }).name,
          filterMultiple: false,
          filters: byAuditTypeList(),
          customRender: (records) => (byAuditTypeList().find((e) => e.value == records) || { name: '' }).name,
        },
        {
          dataIndex: 'email',
          title: '邮箱',
          align: 'left',
          isId: true,
        },
        {
          dataIndex: 'createTime',
          title: '反馈时间',
          filter: true,
          align: 'left',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix(),
        },
        {
          dataIndex: 'content',
          title: '反馈内容',
          filter: true,
          align: 'left',
          sorter: (a, b) => {
            if (a.content && b.content) {
              return Number(a.content.length) - Number(b.content.length)
            }
          },
          customRender: (text, records) => {
            return <div class="content-box">{text}</div>
          },
        },
        {
          dataIndex: 'imgUrl',
          title: '反馈图片',
          type: 'image',
          align: 'left',
          customRender: function (text, records) {
            let imgArr = text ? text.split(',') : []
            return <div class="product-order-item">{text ? imgArr.map((r) => <img src={r} />) : ''}</div>
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.status == '0' ? '处理' : '已处理',
                disabled: records.status !== '0',
                onClick: () => records.status == '0' && _this.handlePropup(records),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除吗?',
                onClick: () =>
                  api.command.delPost.call(this, {
                    url: `/audit/farmAuditFeedback/delete?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    handlePropup(records) {
      apiTool.showDrawer({
        title: '回复',
        width: '600px',
        form: [
          {
            name: '处理结果',
            type: 'textArea',
            key: 'content',
            placeholder: '请输入处理结果备案',
          },
        ],
        success: ({ data, setHidden }) => {
          if (data.content) {
            api.command.editPost
              .call(this, {
                url: `/audit/farmAuditFeedback/replyForFeedback?id=${records.id}&content=${data.content}`,
              })
              .then(setHidden)
          } else {
            this.$message.warning('请填写回复内容！')
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/audit/farmAuditFeedback/deleteBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
    getDrawerForm(records) {
      const form = [
        {
          title: '基础信息',
          display: records.createTime,
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '受理单号',
                  value: records.id,
                  display: records.id,
                },
                {
                  name: '反馈类型',
                  value: (byAuditTypeList().find((e) => e.value == records.auditType) || { name: '' }).name,
                  display: records.auditType,
                },
                {
                  name: '联系手机',
                  value: records.mobile,
                  display: records.mobile,
                },
                {
                  name: '反馈时间',
                  value: records.createTime,
                  display: records.createTime,
                },
                {
                  name: '邮箱',
                  value: records.email,
                  display: records.email,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          title: '问题描述',
          display: records.content,
          form: [
            {
              type: 'text',
              value: records.content,
            },
          ],
        },
        {
          title: '图片',
          display: records.imgUrl,
          form: [
            {
              type: 'imageGroup',
              value: records.imgUrl,
            },
          ],
        },
        {
          title: '处理结果',
          display: records.status == '1' && records.applyContent,
          form: [
            {
              type: 'text',
              value: records.applyContent,
            },
          ],
        },
      ].filter((e) => e.display)
      return form
    },
    onRowSelect(records) {
      const that = this
      getAction('/audit/farmAuditFeedback/getDetail', { id: records.id }, '/api').then((res) => {
        const data = this.getDrawerForm(res.data || records)
        apiTool.showDrawer({
          title: '详情',
          width: '700px',
          view: DrawerForm,
          viewProps: { data },
          success: ({ data, setHidden }) => {
            setHidden()
            that.handlePropup(records)
          },
          foot: ({ close, submit }) => {
            if (records.status == '0') {
              return [
                {
                  name: '取消',
                  onClick: close,
                },
                {
                  name: '处理',
                  type: 'primary',
                  onClick: submit,
                },
              ]
            } else {
              return []
            }
          },
        })
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        onSelectRow={this.onRowSelect}
        records={this.records}
      />
    )
  },
}
</script>
<style scoped lang="less">
.content-box {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
</style>
